<script lang="ts" setup>
import { useModal, VueFinalModal } from 'vue-final-modal'

import EarlyExit from '~/components/modal-windows/EarlyExit.vue'

const model = defineModel<boolean>()

const props = withDefaults(
  defineProps<{
    disableCloseIcon?: boolean
    hasUnsavedChanges?: boolean
    preventUnsavedChanges?: boolean
    size?: 'small' | 'medium' | 'large' | 'extra-large'
  }>(),
  { disableCloseIcon: false, hasUnsavedChanges: false, preventUnsavedChanges: false, size: 'small' }
)

const forceClosing = ref(false)

async function beforeClose (event: { stop: () => void }): Promise<void> {
  const { hasUnsavedChanges, preventUnsavedChanges } = props

  if (!preventUnsavedChanges || forceClosing.value) {
    return
  }

  if (hasUnsavedChanges) {
    event.stop()

    await openEarlyExitModalWindow()
  }
}

async function clickOutside (): Promise<void> {
  const { hasUnsavedChanges, preventUnsavedChanges } = props

  if (!preventUnsavedChanges) {
    return
  }

  if (hasUnsavedChanges) {
    await openEarlyExitModalWindow()
  } else {
    close()
  }
}

function close (): void {
  model.value = false
}

function confirmExit () {
  forceClosing.value = true

  close()
}

async function openEarlyExitModalWindow (): Promise<void> {
  await useModal({ component: EarlyExit, attrs: { onConfirmExit: confirmExit } }).open()
}
</script>

<template>
  <VueFinalModal
    v-model="model"
    :click-to-close="!preventUnsavedChanges"
    :content-class="['custom-modal-window', `custom-modal-window_${size}`]"
    content-transition="vfm-fade"
    :focus-trap="false"
    lock-scroll
    overlay-transition="vfm-fade"
    v-bind="$attrs"
    @before-close="beforeClose"
    @click-outside="clickOutside"
  >
    <SvgIcon
      v-if="!disableCloseIcon"
      class="custom-modal-window__close-icon cursor-pointer"
      name="outlined/close"
      @click="close"
    />

    <div class="custom-modal-window__title text-center">
      <slot name="title" />
    </div>

    <div class="custom-modal-window__content">
      <slot />
    </div>

    <div v-if="$slots.action" class="custom-modal-window__action text-center">
      <slot name="action" />
    </div>
  </VueFinalModal>
</template>

<style lang="scss">
@use 'assets/css/variables';

.custom-modal-window {
  background: white;
  border-radius: 8px;
  box-shadow: 0 16px 64px -2px variables.$main-gray-color;
  box-sizing: border-box;
  max-height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 32px 16px;
  position: relative;

  &_extra-large {
    width: 1200px;
  }

  &_large {
    width: 815px;
  }

  &_medium {
    width: 518px;
  }

  &_small {
    width: 365px;
  }

  &__action {
    margin-top: 24px;
  }

  &__close-icon {
    font-size: 24px;
    position: absolute;
    right: 8px;
    top: 8px;
    transition: all .3s ease;

    &:hover {
      color: variables.$main-red-color;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    margin-bottom: 24px;
  }

  &__content > p {
    line-height: 20px;
    margin-bottom: 20px;
  }
}

.vfm {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 16px 0;

  &__overlay {
    background: rgba(variables.$main-gray-color, 75%);
  }
}

.flatpickr-calendar.open {
  z-index: 9999999999 !important;
}
</style>

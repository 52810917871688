<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import CustomModalWindow from '~/components/modal-windows/CustomModalWindow.vue'

const model = defineModel<boolean>()

const emit = defineEmits<{
  (e: 'confirmExit'): void
}>()

function close (): void {
  model.value = false
}

function confirmExit (): void {
  emit('confirmExit')

  close()
}
</script>

<template>
  <CustomModalWindow v-model="model" :click-to-close="false" disable-close-icon>
    <template #title>
      Закрыть окно без сохранения изменений?
    </template>

    <div class="flex space-x-2">
      <BasicButton color="body-background" title="Нет, остаться" @click="close" />
      <BasicButton color="red" title="Да, закрыть" @click="confirmExit" />
    </div>
  </CustomModalWindow>
</template>
